import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27')
];

export const server_loads = [3,7];

export const dictionary = {
		"/": [9],
		"/alreadyClaimed": [10],
		"/auth/verify": [11,[2]],
		"/checkout/thanks": [13],
		"/checkout/[business]/[card]": [~12],
		"/dashboard": [~14,[3]],
		"/dashboard/account/billing": [~15,[3,4]],
		"/dashboard/account/profile": [~16,[3,4]],
		"/dashboard/events": [17,[3]],
		"/dashboard/events/[id]": [~18,[3]],
		"/login": [~19,[6]],
		"/logout": [~20],
		"/paymentFailed": [21],
		"/presentation/[company]/campaign/[id]": [22,[7]],
		"/presentation/[company]/card/[id]": [23,[7]],
		"/redeem/[code]": [~24],
		"/register": [~25,[8]],
		"/success": [26],
		"/wrongDevice": [27]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';