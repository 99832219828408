import * as Sentry from "@sentry/sveltekit";
import {env} from '$env/dynamic/public';

// If you don't want to use Session Replay, remove the `Replay` integration, 
// `replaysSessionSampleRate` and `replaysOnErrorSampleRate` options.

Sentry.init({
    environment: process.env.NODE_ENV ?? 'development',
    dsn: env.PUBLIC_SENTRY_URL ?? "",
    tracesSampleRate: 1,
    tracePropagationTargets: [],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
    integrations: [Sentry.replayIntegration()]
})

export const handleError = Sentry.handleErrorWithSentry();